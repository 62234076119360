import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from "./Loader";

const UpdateAttendance=()=>{
    const { attendanceId } = useParams();
  const [doctorName, setDoctorName] = useState('');
  const [doctorNameId, setDoctorNameId] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [roomsLeft, setRoomsLeft] = useState('');
  const [successMessage2, setSuccessMessage2] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');

  const [calendarEvents, setCalendarEvents] = useState([]);
  const calendarRef = useRef(null);

  const [numberOfAdults, setNumberOfAdults] = useState('');
  const [numberOfChildren, setNumberOfChildren] = useState('');

  const [fromHour, setFromHour] = useState(0);
  const [fromMinute, setFromMinute] = useState(0);
  const [toMinute, setToMinute] = useState(0);
  const [toHour, setToHour] = useState(0);
  const [status, setStatus] = useState('');

  const [selectedDoctor, setSelectedDoctor] = useState(null);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const database = firebase.database();
        const snapshot = await database.ref('/Doctor Details').once('value');
        const doctorsData = snapshot.val();

        const doctorsOptions = Object.keys(doctorsData).map(key => ({
          value: doctorsData[key].doctorId,
          name: doctorsData[key].Name,
          ...doctorsData[key]
        }));

        setOptions(doctorsOptions);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };

    fetchDoctors();
  }, []);

  useEffect(() => {
    if (attendanceId) {
      const fetchAttendance = async () => {
        try {
            console.log("attendanceId:", attendanceId);
          const database = firebase.database();
          const snapshot = await database.ref(`/Doctor Attendance`).orderByChild('attendanceId').equalTo(parseInt(attendanceId, 10)).once('value');
          console.log("snapshot:", snapshot);
          const attendanceData = snapshot.val();
          console.log("attendancedat:", attendanceData.doctorName);

          if (attendanceData) {
            const attendanceKey = Object.keys(attendanceData)[0];
            const attendance = attendanceData[attendanceKey];
  
            setDoctorNameId(attendance.doctorId);
            setDoctorName(attendance.doctorName);
            setSelectedDoctor(options.find(option => option.value === attendance.doctorId));
            setSelectedDate(new Date(attendance.selectedDate));
            setFromHour(attendance.fromHour);
            setFromMinute(attendance.fromMinute);
            setToHour(attendance.toHour);
            setToMinute(attendance.toMinute);
            setStatus(attendance.status);
          }
        } catch (error) {
          console.error('Error fetching attendance data:', error);
        }
      };

      fetchAttendance();
    }
  }, [attendanceId, options]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!doctorName.length || !selectedDate || !status) {
      setErrorMessage2('Please enter all required fields.');
      setSuccessMessage2('');
      return;
    }

    const fromTime = new Date(selectedDate.setHours(fromHour, fromMinute));
    const toTime = new Date(selectedDate.setHours(toHour, toMinute));
    const timeDifferenceInHours = (toTime - fromTime) / (1000 * 60 * 60); 

    if (status === "Half Day Leave" && timeDifferenceInHours > 5) {
      setErrorMessage2('For "Half Day Leave," the time difference must be 5 hours or less.');
      setSuccessMessage2('');
      return;
    }
  
    if (status === "Present" && timeDifferenceInHours <= 5) {
      setErrorMessage2('For "Present," the time difference must be more than 5 hours.');
      setSuccessMessage2('');
      return;
    }
  
    if (fromTime >= toTime) {
      setErrorMessage2('The "To" time must be later than the "From" time.');
      setSuccessMessage2('');
      return;
    }
  
  
    try {
      const database = firebase.database();
      const attendanceRef = database.ref(`Doctor Attendance`);
  
      // Fetch the record with the given attendanceId
      const snapshot = await attendanceRef.orderByChild('attendanceId').equalTo(parseInt(attendanceId, 10)).once('value');
  
      // Check if any record was found
      const attendanceData = snapshot.val();
      if (attendanceData) {
        const attendanceKey = Object.keys(attendanceData)[0]; // Get the first key
  
        // Prepare updated event data
        const updatedEvent = {
          doctorId: doctorNameId,
          selectedDate: selectedDate.toISOString(),
          fromHour,
          fromMinute,
          toHour,
          toMinute,
          status,
          doctorName: selectedDoctor ? selectedDoctor.name : '',
          attendanceId: parseInt(attendanceId, 10),
        };
  
        // Update the record in the database
        await attendanceRef.child(attendanceKey).update(updatedEvent);
  
        // Update calendar events
        const title = updatedEvent.status === 'Present'
          ? `${updatedEvent.fromHour}:${updatedEvent.fromMinute} to ${updatedEvent.toHour}:${updatedEvent.toMinute}`
          : updatedEvent.status;
  
        const eventForCalendar = {
          title,
          start: new Date(updatedEvent.selectedDate),
          allDay: true,
          backgroundColor: getColorBasedOnCategory(updatedEvent.doctorId),
        };
  
        setCalendarEvents(prevEvents => {
          const updatedEvents = prevEvents.filter(event => event.start.toISOString() !== new Date(updatedEvent.selectedDate).toISOString());
          return [...updatedEvents, eventForCalendar];
        });
  
        // Reset form state
        setDoctorName('');
        setDoctorNameId('');
        setSelectedDate(new Date());
        setFromHour(0);
        setFromMinute(0);
        setToHour(0);
        setToMinute(0);
        setStatus('');
  
        setSuccessMessage2('Form data submitted successfully.');
        setErrorMessage2('');
        console.log('Form data submitted successfully.');
      } else {
        setErrorMessage2('No record found with the provided attendanceId.');
        console.error('No record found with the provided attendanceId.');
      }
    } catch (error) {
      setErrorMessage2('Error submitting form data. Please try again.');
      console.error('Error submitting form data:', error);
      setSuccessMessage2('');
    }
  };
  
  const handleDoctorChange = (event) => {
    const selectedName = event.target.value;
    const doctor = options.find(option => option.name === selectedName);
    
    if (doctor) {
      setDoctorName(selectedName);
      setDoctorNameId(doctor.value);
      setSelectedDoctor(doctor);
    }
  };
  
  const getColorBasedOnCategory = (category) => {
    switch (category) {
      case '1':
        return '#878cde';
      case '2':
        return '#74b874';
      case '3':
        return '#a18467';
      default:
        return '#9b7d7d';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const database = firebase.database();
        const snapshot = await database.ref('/Doctor Attendance').once('value');
        const roomData = snapshot.val();
        const latestEvents = {};

        for (const key in roomData) {
          if (roomData.hasOwnProperty(key)) {
            const eventData = roomData[key];
            const eventId = eventData.doctorName + eventData.selectedDate;

            if (!latestEvents[eventId] || new Date(eventData.selectedDate) >= new Date(latestEvents[eventId].selectedDate)) {
              latestEvents[eventId] = eventData;
            }
          }
        }

        const events = Object.values(latestEvents).map(eventData => {
          const title = (eventData.status === 'Present' || eventData.status === 'Half Day Leave')
            ? `${eventData.fromHour}:${eventData.fromMinute} to ${eventData.toHour}:${eventData.toMinute}`
            : eventData.status;
        
          return {
            title,
            start: new Date(eventData.selectedDate),
            allDay: true,
            backgroundColor: getColorBasedOnCategory(eventData.doctorId)
          };
        });

        setCalendarEvents(events);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const renderEventContent = (eventInfo) => {
    return (
      <div className="event-content" style={{ backgroundColor: eventInfo.backgroundColor, textAlign: 'center' }}>
        {eventInfo.timeText && <div className="event-time">{eventInfo.timeText}</div>}
        <div className="event-title">{eventInfo.event.title}</div>
      </div>
    );
  };
    return(
        <div className="App">
            <Header/>
            <Loader/>
            <div className="container-fluid" style={{ marginTop: "200px" }}>
        <h1 style={{ color: "#853f91", textAlign: "center" }}>Doctor Attendance</h1><br></br>
        <div className="col-md-12" style={{ marginTop: "40px" }}>
          <div className='row' style={{display:"flex", justifyContent:"left"}}>
            {options.map(option => (
              <div className="col-auto" key={option.value}>
                <div className="box" style={{ backgroundColor: getColorBasedOnCategory(option.value), textAlign:"center", borderRadius:"5px", padding: "10px", margin: "5px"}}>
                  {option.name}
                </div>
              </div>
            ))}
          </div>
        </div><br></br>

        <div className="col-md-12">
          <div className="mb-3">
            <Fullcalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView={"dayGridMonth"}
              headerToolbar={{
                start: "today prev,next",
                center: "title",
                end: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              buttonText={{
              today: 'Today',
              month: 'Month',
              week: 'Week',
              day: 'Day',
            }}
              height={"60vh"}
              eventContent={renderEventContent}
              events={calendarEvents}
              themeSystem="customTheme"
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="mb-3">
            <form onSubmit={handleSubmit}>
              <div className="row">
              <div className="col-md-2 mb-3">
                    <label htmlFor="doctorName" className="form-label">Doctor Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="doctorName"
                        value={doctorName}  // Display the selected doctor's name
                        onChange={handleDoctorChange}
                        placeholder="Select a Doctor"
                        disabled
                    />
                    </div>

                <div className="col-md-2 " style={{width:"150px"}}>
                  <label htmlFor="datepicker" className="form-label">Select Date</label>
                  <DatePicker
                    className="form-control"
                    id="datepicker"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="status" className="form-label">Status</label>
                  <div className="d-flex">
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="present"
                        value="Present"
                        checked={status === 'Present'}
                        onChange={(e) => setStatus(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="present" style={{fontSize:"12px",fontWeight:"normal"}}>
                        Present
                      </label>
                    </div>
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="leave"
                        value="Leave"
                        checked={status === 'Leave'}
                        onChange={(e) => {
                          setStatus(e.target.value);
                          setFromHour(0);
                          setFromMinute(0);
                          setToHour(0);
                          setToMinute(0);
                        }}
                      />
                      <label className="form-check-label" htmlFor="leave" style={{fontSize:"12px",fontWeight:"normal"}}>
                        Leave
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="halfDayLeave"
                        value="Half Day Leave"
                        checked={status === 'Half Day Leave'}
                        onChange={(e) => setStatus(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="halfDayLeave" style={{fontSize:"12px",fontWeight:"normal"}}>
                        Half Day Leave
                      </label>
                    </div>
                  </div>
                </div>
              
                <div className="col-md-2 mb-3" style={{width:"150px"}}>
                  <label htmlFor="fromTime" className="form-label">From</label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      id="fromHour"
                      value={fromHour}
                      onChange={(e) => setFromHour(e.target.value)}
                      disabled={status === "Leave"}
                    >
                      {[...Array(24)].map((_, hour) => (
                        <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                      ))}
                    </select>
                    <span className="input-group-text">:</span>
                    <select
                      className="form-control"
                      id="fromMinute"
                      value={fromMinute}
                      onChange={(e) => setFromMinute(e.target.value)}
                      disabled={status === "Leave"}
                    >
                      {[...Array(60)].map((_, minute) => (
                        <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-2 mb-3" style={{width:"150px"}}>
                  <label htmlFor="toTime" className="form-label">To</label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      id="toHour"
                      value={toHour}
                      onChange={(e) => setToHour(e.target.value)}
                      disabled={status === "Leave"}
                    >
                      {[...Array(24)].map((_, hour) => (
                        <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                      ))}
                    </select>
                    <span className="input-group-text">:</span>
                    <select
                      className="form-control"
                      id="toMinute"
                      value={toMinute}
                      onChange={(e) => setToMinute(e.target.value)}
                      disabled={status === "Leave"}
                    >
                      {[...Array(60)].map((_, minute) => (
                        <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {successMessage2 && <div className="text-center alert-success mt-3">{successMessage2}</div>}
              {errorMessage2 && <div className="text-center alert-danger mt-3">{errorMessage2}</div>}
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                  <button type="submit" className="btn btn-primary" style={{ width: "150px" }}>Submit</button>
                </div>
              </div>

            
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    );
}

export default UpdateAttendance;